import {ColumnProps,EnumProps,EnumColumnUse} from '@/components'
import {defineEmits} from "vue"
import {RList,RListData,getGatewayManage,addGatewayManage,updateGatewayManage,deleteGatewayManage,IMYGatewaySearch,IMYGateway, 
    IMenuItem,PageOpenWith,getIsAliveEnum,getTypeEnum,getOrgEnum} from '@/ts'

// const labelListMenu:IMenuItem={ id: "201", name: 'gatewayManage', title: "网关管理", icon: "icontongjichaxun", url:"/base/BaseList",
//     btns:[
//         {active:true,name:"gatewayAdd",title:"添加网关",icon:"Plus", type:"primary",model:PageOpenWith.EVENT, routerPath:"Add"},
//         {active:true,name:"gatewayListAdd",title:"从已注册网关添加",icon:"Plus", model:PageOpenWith.DIALOG, routerPath:"/print/PrintSet"},
//         {active:true,name:"gatewaySetting",title:"网关配置",icon:"Setting", model:PageOpenWith.DIALOG, routerPath:"/print/PrintSet"},
//     ]
// };
const emit=defineEmits(['open']);
console.log('>>>1',emit);
// const openTab=(a)=>{
//     console.log('>>>2',emit);
//     console.log('>>>3',a);
//     // ctx.emit('open', labelListMenu);
// }
const config:RList<IMYGateway, IMYGatewaySearch>={
    title: "网关",
    // 列表数据接口
    requestApi:async (param:IMYGatewaySearch):Promise<RListData<IMYGateway>> => {
        if(param.createTime) {
            param.beginTime=param.createTime[0];
            param.endTime=param.createTime[1];
            delete param.createTime;
        }
        const data=await getGatewayManage(param);
        
        return {
            list:data.list,
            pageNum:data.current,
            pageSize:data.pageSize,
            total:data.total
        }; 
    },
    // 新增接口
    addApi:async(row:IMYGateway):Promise<boolean>=>{
        const data=await addGatewayManage(row)
        console.log('新增接口');
        return data;
    },
    
    // 修改接口
    editApi:async(row:IMYGateway):Promise<boolean>=>{
        const data=await updateGatewayManage(row)
        console.log('修改接口');
        return data;
    },
    // 删除接口
    /* deleteApi:(param:{id:string[]}):boolean=>{
        console.log('删除接口');
        return true;
    }, */
    // 单行删除接口
    deleteApi:async(ids:string[]):Promise<boolean>=>{
        const data=await deleteGatewayManage(ids)
        console.log('删除接口');
        return data;
    },
    selectId:"areaId",
    // 表格列信息
    columns:[
        { prop:"areaId", type: "selection", fixed: "left", width: 55 , use:EnumColumnUse.LIST},
        // { type: "index", label: "#", width: 80 , use:EnumColumnUse.LIST},
        { type: "expand", label: "Expand", width: 100 , use:EnumColumnUse.LIST},
    
        {prop:"deviceId", label:"网关ID", width:"250", el: "input",
            search: { key:"key", label:"关键字查询", props:{placeholder:"网关ID、MAC地址、网关位置"}}, 
            edit: {props:{disabled:true}},
            use:EnumColumnUse.LIST|EnumColumnUse.SEARCH|EnumColumnUse.ADD|EnumColumnUse.MODIFY},
        {prop:"createTime", label:"创建时间", width:"210", el:"date-picker", 
            search:{label:"创建时间",props: { type: "daterange",valueFormat: "YYYY-MM-DD"}}, 
            use:EnumColumnUse.SEARCH},
        {prop:"type", label:"网关类型", width:"210", el:"select", 
            search:{ }, 
            enum: getTypeEnum(), use:EnumColumnUse.LIST|EnumColumnUse.ADD|EnumColumnUse.MODIFY},
        {prop:"bluetoothMacAddress", label:"蓝牙MAC地址", width:"150", el: "input",
            search: { }, 
            use:EnumColumnUse.LIST|EnumColumnUse.ADD|EnumColumnUse.MODIFY},
        {prop:"org",enum:getOrgEnum(), label:"所属单位", width:"180", el: "select",
            search: { }, 
            use:EnumColumnUse.ADD|EnumColumnUse.MODIFY},
        {prop:"location", label:"网关位置", width:"250", el: "input",
            search: { }, 
            enum: getOrgEnum(), use:EnumColumnUse.LIST|EnumColumnUse.ADD|EnumColumnUse.MODIFY},
        {prop:"labelCount", label:"接入网关标签", width:"150", 
            render: scope => {
                return (
                    <el-button type="primary" link >
                        查看标签({scope.row.labelCount})
                    </el-button>
                );
            },
            use:EnumColumnUse.LIST},
        {prop:"isAlive", label:"是否在线", width:"180", el: "select" ,
            search: { key:"isOnline", label:"网关状态", props: { filterable: true }}, 
            enum:getIsAliveEnum(), use:EnumColumnUse.LIST|EnumColumnUse.SEARCH},
        {prop:"voltage", label:"电压", width:"80", use:EnumColumnUse.LIST},
        {prop:"temperature", label:"温度", width:"80", use:EnumColumnUse.LIST},
        {prop:"updateTime", label:"更新时间", width:"180", use:EnumColumnUse.LIST},
        { prop: "operation", label: "操作", fixed: "right", width: 150, use:EnumColumnUse.LIST}
    ],
    rules:{
        deviceId:[
            {required: true, message: '这是必填项', trigger: 'blur'},
            {pattern:/^AE0[1-3][2-9][1-9A-C][0-3][0-9][a-zA-Z0-9]{16}$/, message:'网关ID格式有误！'}
        ],
        type:[
            {required: true, message: '这是必填项', trigger: 'blur'}
        ],
        org:[
            {required: true, message: '这是必填项', trigger: 'blur'},
        ],
        location:[
            {required: true, message: '这是必填项', trigger: 'blur'},
        ]
    }
}

export default config;